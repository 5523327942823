import createAuth0Client from '@auth0/auth0-spa-js'

async function main() {
  const auth0 = await createAuth0Client(
    window.location.hostname.endsWith('hgraph.io')
      ? {
          domain: 'auth.hgraph.io',
          client_id: 'S4vP9fyKDAgpTP0OZJgUtO9l6URyrge7',
          audience: 'https://api.hgraph.io',
          cacheLocation: 'localstorage',
        }
      : {
          domain: 'dev-bn8ju957.us.auth0.com',
          client_id: 's2VvUUG5eF1lfQ1vprKcTbiCDaInZXB4',
          audience: 'https://api.hgraph.io',
          cacheLocation: 'localstorage',
        }
  )

  window.auth0 = auth0

  /**
   * Starts the authentication flow
   */
  window.login = async (options = {}) => {
    await auth0.loginWithRedirect({
      redirect_uri: `${window.location.origin}/${window.location.search}`,
      ...options,
      // screen_hint: 'signup' - pass this option to go to signup page instead
    })
  }

  /**
   * Executes the logout flow
   */
  window.logout = async () => {
    await auth0.logout({
      returnTo: window.location.origin,
    })
  }

  /*
   * handle login callback, preserving other querystrings
   */
  const params = new URLSearchParams(window.location.search)
  if (params.has('code') && params.has('state')) {
    await auth0.handleRedirectCallback()

    params.delete('code')
    params.delete('state')

    const querystring = params.toString()
    const url = querystring
      ? `${window.location.pathname}?${querystring}`
      : window.location.pathname

    //Use replaceState to remove the querystring parameters
    window.history.replaceState({}, document.title, url)
  }

  /*
   * dispatch event
   */
  if (!(await auth0.isAuthenticated())) window.login()
  else
    window.dispatchEvent(
      new CustomEvent('userDidAuthenticate', {detail: await auth0.getTokenSilently()})
    )
}

main()
