//https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// TODO: this is only for visuals, this does not actually verify signature of the token
function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

async function getRole() {
  const jwt = await window.auth0.getTokenSilently()
  const claims = parseJwt(jwt)
  return claims['https://hasura.io/jwt/claims']['x-hasura-default-role']
}

//TODO: this is a workaround for dev server adding trailing slashes
// TODO: could also do this in the transform stage with cheerio
const menuSelector =
  location.pathname === '/' // homepage
    ? '/'
    : location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname

// add active class to side navigation
document
  .querySelector('nav.side-navigation')
  ?.querySelector(`a[href="${menuSelector}"]`)
  .classList.add('active')

/*
 * set select network value
 */
window.addEventListener('networkDidChange', () => {
  const network = localStorage.getItem('network') || 'MAINNET'
  document
    .querySelectorAll('select[name="network"]')
    .forEach((element) => (element.value = network))
})
